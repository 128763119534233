(function ($) {
  // When the menu changes, update the quantity on the add-to-cart buttons
  $(document).on('change', 'select.js-quantity-menu', function () {
    var $self = $(this);
    var $prod = $self.closest('.js-product');
    var qty = parseInt($self.val());

    $prod.find('.js-add-to-cart, js-add-to-bag').data('qty', qty);
    if (Drupal.settings.common.clearpay_unit_price) {
      Drupal.behaviors.productInstallmentPriceUKV1.initClearPay();
    }
    if (Drupal.settings.afterpay_price) {
      Drupal.behaviors.afterPayInit.initAfterPay();
    }
  });
})(jQuery);
